export default [
    {
        path: '/programme/:programmeId/',
        name: 'ApplicationContainer',
        component: () => import(/* webpackChunkName: "applications" */ '../views/ApplicationContainer.vue'),
        props: true,
        children: [
            {
                path: 'housejob/',
                name: 'ApplicationFormHousejob',
                component: () => import(/* webpackChunkName: "applications" */ '../views/application_forms/ApplicationFormHousejob.vue'),
                props: true,

            },
            {
                path: 'mdcat/',
                name: 'ApplicationFormMDCAT',
                component: () => import(/* webpackChunkName: "applications" */ '../views/application_forms/ApplicationFormMDCAT.vue'),
                props: true,

            },
            {
                path: 'regular/',
                name: 'ApplicationFormWithoutMDCAT',
                component: () => import(/* webpackChunkName: "applications" */ '../views/application_forms/ApplicationFormWithoutMDCAT.vue'),
                props: true,

            },
            {
                path: 'postgrad/',
                name: 'ApplicationFormPostgrad',
                component: () => import(/* webpackChunkName: "applications" */ '../views/application_forms/ApplicationFormPostgrad.vue'),
                props: true,

            },
        ]
    },
]