import { defineStore } from "pinia";
import { ref } from 'vue'
import axios from "axios";

export const useGeneralStore = defineStore('general', () => {
    const colleges = ref([]);
    const notifications = ref([]);

    function fetchColleges() {
        if (process.env.VUE_APP_DEBUG === '1')
            colleges.value = debug_colleges

        let url = "/colleges/"
        axios.get(url)
            .then(res => {
                colleges.value = res.data;
            })
    }

    function fetchNotifications() {
        if (process.env.VUE_APP_DEBUG === '1')
            notifications.value = debug_notifications

        let url = "/university/notifications/"
        axios.get(url)
            .then(res => {
                notifications.value = res.data;
            })
    }

    function fetch() {
        fetchColleges();
        fetchNotifications();
    }

    return {
        colleges,
        notifications,
        fetch, fetchNotifications, fetchColleges
    }
})

let debug_colleges = [
    {
        "id": 2,
        "has_housejob": true,
        "name": "First College",
        "subheading": "Sincerity to Cause",
        "logo": "https://via.placeholder.com/200x200/",
        "detail": "College detail goes here with some lorem ipsum is a thing.",
        "description": "lorem ipsum is a dummy text used for testing purposes. It is not meant to be a real text.",
        "order": 10
    },
    {
        "id": 1,
        "has_housejob": true,
        "name": "Second College",
        "subheading": "Perseverance to Succeed",
        "logo": "https://via.placeholder.com/200x200/",
        "detail": "College detail goes here with some lorem ipsum is a thing.",
        "description": "lorem ipsum is a dummy text used for testing purposes. It is not meant to be a real text.",
        "order": 9
    },
]
let debug_notifications = [
    {
        "id": 6,
        "text": "All the candidates applying for the House Job in the affiliated teaching hospitals of Kabir Medical College shall apply to PMC for a Provisional License for House Job before submitting their application.",
        "date": "2022-04-15"
    },
    {
        "id": 3,
        "text": "Lorem ipsum is simply dummy text.",
        "date": "2022-04-15"
    }
]