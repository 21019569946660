<template>
  <div class="college">
    <SiteHeader
      v-if="college_detail"
      :title="college_detail.name"
      :subtitle="college_detail.subheading"
      :img="college_detail.logo"
    />
    <SiteHeader
      v-else
      title="Gandhara University"
      subtitle="Admission Portal"
      img="/logo.png"
    />

    <loading-overlay v-if="loading" />
    <div v-else class="row m-0">
      <div class="col-md-7 col-lg-9">
        <h2 class="uc text-light bg-blue p-3 shadow text-center">Programmes</h2>
        <div v-if="programmes.length > 0">
          <div
            class="tdn text-light big"
            v-for="programme in programmes"
            :key="programme.id"
          >
            <div class="p-4 bg-white my-2 text-dark shadow-sm text-center">
              <h2 v-if="programme.name === 'PG'">Postgraduate Admissions</h2>
              <h2 v-else>
                {{ programme.name }}
              </h2>
              <p class="small">{{ programme.description }}</p>
              <hr />

              <div v-if="programme.admissions_open" class="pb-2">
                <p class="px-4 smaller">
                  Admissions in this programme are currently open, the deadline
                  for admissions is:
                  <span class="text-red fw-bold">
                    {{ formatDate(programme.end_date) }}
                  </span>
                  - Eligible candidates are required to submit their online
                  application along with required documents at the earliest.
                </p>
                <br />
                <a
                  @click="startApplication(programme)"
                  class="btn btn-blue small tdn text-light p-3 px-5"
                >
                  Start Application
                </a>
              </div>
              <div v-else class="pb-2">
                <p class="smaller text-red px-4">
                  Admissions in this programme are currently not available -
                  Applicants are requested to wait for the enrollment process to
                  begin.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="p-4 lead text-center bg-white my-2 shadow-sm">
          There doesn't seem to be anything here, please check back later.
        </div>
      </div>
      <div class="col">
        <h2 class="uc text-light bg-blue p-3 shadow text-center">
          description
        </h2>
        <p class="p-4 bg-white my-2 shadow-sm">
          {{ college_detail.description }}
        </p>
        <h2 class="uc text-light bg-blue p-3 shadow text-center">Downloads</h2>
        <a
          class="tdn text-dark big"
          v-for="download in downloads"
          :key="download.id"
          :href="download.file"
          download
        >
          <div class="p-4 bg-white my-2 shadow-sm">
            {{ download.name }}
            <div class="smaller text-grey">Click to download</div>
          </div>
        </a>
        <div v-if="downloads.length == 0" class="p-4 bg-white my-2 shadow-sm">
          There doesn't seem to be anything here, please check back later.
        </div>
        <h2 class="uc text-light bg-blue p-3 shadow text-center">Detail</h2>
        <p class="p-4 bg-white my-2 shadow-sm">
          {{ college_detail.detail }}
        </p>
      </div>
    </div>
  </div>
</template>
<script setup>
let debug = false;
if (process.env.VUE_APP_DEBUG === "1") debug = true;

import SiteHeader from "@/components/SiteHeader.vue";
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import { functions } from "@/components/functions.js";
import { onMounted, ref } from "vue";

const college_detail = ref(null);
const programmes = ref([]);
const downloads = ref([]);
const loading = ref(true);

const props = defineProps({
  collegeId: {
    required: true,
  },
});

onMounted(() => {
  if (debug) {
    loading.value = false;
    college_detail.value = {
      id: 2,
      has_housejob: true,
      name: "Top medical college",
      subheading: "Sincerity to topness",
      logo: "https://via.placeholder.com/200x200/",
      detail: "College detail goes here with some lorem ipsum is a thing.",
      description:
        "lorem ipsum is a dummy text used for testing purposes. It is not meant to be a real text.",
      order: 9,
    };
    programmes.value = [
      {
        id: 18,
        admissions_open: false,
        end_date: null,
        logo: "https://admissions.gandhara.edu.pk/media/kmc.png",
        eligibility_qualification_display: "Bachelors Percentage",
        name: "PG",
        description:
          "Postgraduate admissions for M.Phil Chemical Pathology, M.Phil Forensic Medicine, M.Phil Bio Chemistry, M.Phil Anatomy",
        eligibility_qualification: "B",
        eligibility_maximum: 1100,
        eligibility_criteria: "65.00",
        is_academic: true,
        is_five_year: true,
        has_mdcat: false,
        has_bachelors: true,
        college: 2,
      },
      {
        id: 2,
        admissions_open: true,
        end_date: "2022-12-31",
        logo: "https://admissions.gandhara.edu.pk/media/kmc.png",
        eligibility_qualification_display:
          "FSc (Pre-medical) / Equivalent Marks",
        name: "MBBS",
        description: "Bachelor of Medicine & Bachelor of Surgery",
        eligibility_qualification: "F",
        eligibility_maximum: 1100,
        eligibility_criteria: "60.00",
        is_academic: true,
        is_five_year: false,
        has_mdcat: true,
        has_bachelors: false,
        college: 2,
      },
    ];
  }
  getCollegeDetail();
  getProgrammes();
  getDownloads();
});

import { useRouter } from "vue-router";
const Router = useRouter();

function startApplication(programme) {
  if (programme.is_academic == false)
    Router.push({
      name: "ApplicationFormHousejob",
      params: { programmeId: programme.id },
    });
  else if (programme.is_academic && programme.has_bachelors)
    Router.push({
      name: "ApplicationFormPostgrad",
      params: { programmeId: programme.id },
    });
  else if (programme.has_mdcat == true)
    Router.push({
      name: "ApplicationFormMDCAT",
      params: { programmeId: programme.id },
    });
  else if (programme.has_mdcat == false)
    Router.push({
      name: "ApplicationFormWithoutMDCAT",
      params: { programmeId: programme.id },
    });
}

function formatDate(date) {
  return functions.formatDateToLongFormat(date);
}

import axios from "axios";

function getCollegeDetail() {
  let url = `/colleges/${props.collegeId}/`;
  axios.get(url).then((response) => {
    college_detail.value = response.data;
    loading.value = false;
  });
}

function getProgrammes() {
  let url = `/programmes/?college=${props.collegeId}`;
  axios.get(url).then((response) => {
    programmes.value = response.data;
  });
}

function getDownloads() {
  let url = `/colleges/downloads/?college=${props.collegeId}`;
  axios.get(url).then((response) => {
    downloads.value = response.data;
  });
}
</script>