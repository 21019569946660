export default [
    {
        path: '/staff',
        name: 'StaffContainer',
        component: () => import(/* webpackChunkName: "staff" */ '../views/staff/StaffContainer.vue'),
        children: [
            {
                path: '',
                name: 'StaffDashboard',
                component: () => import(/* webpackChunkName: "staff" */ '../views/staff/StaffDashboard.vue'),
            },
            {
                path: 'sessions/',
                component: () => import(/* webpackChunkName: "staff" */ '../views/staff/academic/StaffSessionsContainer.vue'),
                children: [
                    {
                        path: '',
                        name: 'StaffSessionsList',
                        component: () => import(/* webpackChunkName: "staff" */ '../views/staff/academic/StaffSessionsList.vue'),
                    },
                    {
                        path: ':sessionID/',
                        name: "StaffSessionDetail",
                        component: () => import(/* webpackChunkName: "staff" */ '../views/staff/academic/StaffSessionDetail.vue'),
                        props: true
                    },
                    {
                        path: ':sessionID/applications/:category/',
                        name: "StaffApplicationList",
                        component: () => import(/* webpackChunkName: "staff" */ '../views/staff/academic/StaffApplicationList.vue'),
                        props: true
                    },
                    {
                        path: 'applications/:applicationID/',
                        name: 'StaffApplicationDetail',
                        component: () => import(/* webpackChunkName: "staff" */ '../views/staff/academic/StaffApplicationDetail.vue'),
                        props: true,
                    },
                    {
                        path: 'applications/postgrad/:applicationID/',
                        name: 'StaffPostgradAppDetail',
                        component: () => import(/* webpackChunkName: "staff" */ '../views/staff/academic/StaffPostgradAppDetail.vue'),
                        props: true,
                    },
                    {
                        path: 'applications/:applicationID/print/',
                        name: 'StaffApplicationPrint',
                        component: () => import(/* webpackChunkName: "staff" */ '../views/staff/academic/StaffApplicationPrint.vue'),
                        props: true,
                    }

                ],
            },
            {
                path: 'hj-sessions/',
                component: () => import(/* webpackChunkName: "staff" */ '../views/staff/housejob/StaffHJSessionsContainer.vue'),
                children: [
                    {
                        path: '',
                        name: 'StaffHJSessionsList',
                        component: () => import(/* webpackChunkName: "staff" */ '../views/staff/housejob/StaffHJSessionsList.vue'),
                    },
                    {
                        path: ':sessionID/',
                        name: "StaffHJSessionDetail",
                        component: () => import(/* webpackChunkName: "staff" */ '../views/staff/housejob/StaffHJSessionDetail.vue'),
                        props: true
                    },
                    {
                        path: ':sessionID/applications/:category/',
                        name: "StaffHJApplicationList",
                        component: () => import(/* webpackChunkName: "staff" */ '../views/staff/housejob/StaffHJApplicationList.vue'),
                        props: true
                    },
                    {
                        path: 'applications/:applicationID/',
                        name: 'StaffHJApplicationDetail',
                        component: () => import(/* webpackChunkName: "staff" */ '../views/staff/housejob/StaffHJApplicationDetail.vue'),
                        props: true,
                    }

                ],
            },
            {
                path: 'accounts/',
                component: () => import(/* webpackChunkName: "accounts" */ '../views/staff/accounts/StaffAccountsContainer.vue'),
                children: [
                    {
                        path: '',
                        name: 'StaffAccountsList',
                        component: () => import(/* webpackChunkName: "accounts" */ '../views/staff/accounts/StaffAccountsList.vue'),
                    },
                    {
                        path: ':userID/',
                        name: 'StaffAccountsDetail',
                        component: () => import(/* webpackChunkName: "accounts" */ '../views/staff/accounts/StaffAccountsDetail.vue'),
                        props: true,
                    },
                    {
                        path: 'logs/',
                        name: "StaffAccountLogs",
                        component: () => import(/* webpackChunkName: "accounts" */ '../views/staff/accounts/StaffAccountsLogs.vue'),
                    },
                ],
            },
            {
                path: 'merit/',
                component: () => import(/* webpackChunkName: "merit" */ '../views/staff/merit/StaffMeritContainer.vue'),
                children: [
                    {
                        path: '',
                        name: 'meritSessionsList',
                        component: () => import(/* webpackChunkName: "merit" */ '../views/staff/merit/StaffMeritSessionsList.vue'),
                    },

                    // to be removed //
                    // {
                    //     path: ':sessionID/',
                    //     name: 'meritListDetail',
                    //     component: () => import(/* webpackChunkName: "merit" */ '../views/staff/merit/StaffMeritListDetail.vue'),
                    //     props: true,
                    // },
                    // {
                    //     path: ':sessionID/detailed/',
                    //     name: 'meritCompleteDetail',
                    //     component: () => import(/* webpackChunkName: "merit" */ '../views/staff/merit/StaffCompleteDetailedList.vue'),
                    //     props: true,
                    // },
                    {
                        path: ':sessionID/admissions/',
                        name: 'meritAdmissions',
                        component: () => import(/* webpackChunkName: "merit" */ '../views/staff/merit/StaffMeritListAdmissions.vue'),
                        props: true,
                    },

                    // new views //
                    {
                        path: ':sessionID/v2/',
                        name: 'meritListDetail_v2',
                        component: () => import(/* webpackChunkName: "merit" */ '../views/staff/merit/v2/StaffMeritListDetail.vue'),
                        props: true,
                    },
                    {
                        path: ':sessionID/detailed/v2/',
                        name: 'meritCompleteDetail_v2',
                        component: () => import(/* webpackChunkName: "merit" */ '../views/staff/merit/v2/StaffMeritListComplete.vue'),
                        props: true,
                    },
                    {
                        path: ':sessionID/admissions/v2/',
                        name: 'meritAdmissions_v2',
                        component: () => import(/* webpackChunkName: "merit" */ '../views/staff/merit/v2/StaffMeritListAdmissions.vue'),
                        props: true,
                    },
                ],
            },
            {
                path: 'mailing/',
                component: () => import(/* webpackChunkName: "mailing" */ '../views/staff/mailing/StaffMailingContainer.vue'),
                children: [
                    {
                        path: '',
                        name: 'mailingSessionsList',
                        component: () => import(/* webpackChunkName: "mailing" */ '../views/staff/mailing/StaffMailingSessionsList.vue'),
                    },
                ],
            },
            {
                path: 'sms/',
                component: () => import(/* webpackChunkName: "mailing" */ '../views/staff/sms/StaffSMSContainer.vue'),
                children: [
                    {
                        path: 'bulk/',
                        name: 'smsBulk',
                        component: () => import(/* webpackChunkName: "mailing" */ '../views/staff/sms/StaffSMSBulk.vue'),
                    },
                    {
                        path: 'auto/',
                        name: 'smsAuto',
                        component: () => import(/* webpackChunkName: "mailing" */ '../views/staff/sms/StaffSMSAuto.vue'),
                    },
                    {
                        path: 'group/',
                        name: 'smsGroup',
                        component: () => import(/* webpackChunkName: "mailing" */ '../views/staff/sms/StaffSMSGroup.vue'),
                    },
                ],
            },
            {
                path: 'downloads/',
                component: () => import(/* webpackChunkName: "downloads" */ '../views/staff/downloads/DownloadsContainer.vue'),
                children: [
                    {
                        path: '',
                        name: 'downloadsHome',
                        component: () => import(/* webpackChunkName: "downloads" */ '../views/staff/downloads/DownloadsHome.vue'),
                    },
                ],
            },
            {
                path: 'notifications/',
                component: () => import(/* webpackChunkName: "notifications" */ '../views/staff/notifications/NotificationsContainer.vue'),
                children: [
                    {
                        path: '',
                        name: 'notificationsHome',
                        component: () => import(/* webpackChunkName: "notifications" */ '../views/staff/notifications/NotificationsHome.vue'),
                    },
                ],
            },
            {
                path: 'logs/',
                component: () => import(/* webpackChunkName: "notifications" */ '../views/staff/logs/LogsContainer.vue'),
                children: [
                    {
                        path: '',
                        name: 'logsHome',
                        component: () => import(/* webpackChunkName: "notifications" */ '../views/staff/logs/LogsHome.vue'),
                    },
                ],
            },
        ]
    },
]