<template>
  <div class="home text-center text-light">
    <SiteHeader :title="name" :subtitle="subheading" :img="logo" />
    <div class="row m-0">
      <!-- <div class="col-md-12 mt-3">
        <div class="bg-blue p-2">
          <a
            href="https://admissions.gandhara.edu.pk/media/attachments/2022/07/Application_Form_CBT_fOHYLf3.pdf"
            class="text-yellow tdn"
            download
            >Click here to download <b>CBT Application Form</b></a
          >
        </div>
      </div> -->
      <div v-if="GeneralStore.notifications.length" class="col-md-12 mb-3">
        <div class="latest_notif text-light bg-red p-2">
          <b>Latest Notification: </b>{{ GeneralStore.notifications[0].text }}
        </div>
      </div>
      <div class="col-md-7 col-lg-9">
        <div class="row text-center">
          <div class="col-md-12">
            <h2 class="uc text-light bg-blue p-3">Our Institutes</h2>
          </div>
          <LoadingArea v-if="GeneralStore.colleges.length == 0" />
          <div
            class="col-md-6"
            v-for="college in GeneralStore.colleges"
            :key="college.id"
          >
            <div class="p-3">
              <div class="row p-2 bg-white border">
                <div class="col-md-4">
                  <img
                    style="
                      width: 100%;
                      max-width: 175px;
                      height: auto;
                      margin: 1em auto;
                    "
                    :src="college.logo"
                  />
                </div>
                <div class="col-md text-dark">
                  <h2 class="fw-light">
                    {{ college.name }}
                  </h2>
                  <router-link
                    :to="'/college/' + college.id"
                    class="tdn btn btn-outline-blue w-100 mb-2"
                  >
                    Admissions
                  </router-link>
                  <router-link
                    v-if="college.has_housejob"
                    :to="'/college/' + college.id + '/housejob/'"
                    class="tdn btn btn-outline-blue w-100 mb-2"
                  >
                    House Job
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <div class="col-md-12 mb-3">
            <div class="shadow-sm">
              <h2 class="uc text-light bg-blue p-3 mb-0 shadow-sm">
                Instructions
              </h2>
              <div class="p-4 big text-dark bg-white text-start">
                <p class="small">
                  Welcome to the Gandhara University Web Portal!
                </p>
                <p class="small">
                  For applications, go to the Admissions page of the institute
                  that you're interested in applying to, likewise for House Job
                  applications.
                </p>
                <p class="small">
                  All applicants are advised that they should keep all relevant
                  documents nearby during the application process as we will be
                  requiring scanned copies or legible photographs of your
                  academic as well as identification documents.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <h2 class="uc text-light bg-blue p-3 shadow mb-0">Notifications</h2>
            <LoadingArea v-if="GeneralStore.notifications.length == 0" />
            <div
              v-for="each in GeneralStore.notifications"
              :key="each.id"
              class="
                bg-white
                pt-1
                text-red
                fw-bold
                shadow-sm
                w-100
                d-block
                text-decoration-none
              "
            >
              <p class="px-3 pre-line">
                {{ each.text }} -
                <span class="smaller text-red">{{ each.date }}</span>
              </p>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@keyframes pulse {
  0% {
    background: #d73b3b;
  }

  25% {
    background: #b12929;
  }
  100% {
    background: #d73b3b;
  }
}

.latest_notif {
  background: rgb(151, 13, 13);
  animation: pulse 1.5s infinite;
}
</style>

<script setup>
import SiteHeader from "@/components/SiteHeader.vue";
import { ref, onMounted } from "vue";
import { useGeneralStore } from "@/stores/general";

const name = ref("Gandhara University");
const subheading = ref("Admissions Portal");
const logo = ref("/logo.png");

const GeneralStore = useGeneralStore();

onMounted(() => {
  GeneralStore.fetch();
});
</script>
