<template>
  <div id="vue_app">
    <div id="nav">
      <TopBar class="" />
    </div>
    <router-view style="min-height: 800px;" id="content-body" />

    <SiteFooter />
  </div>
</template>
<style lang="scss">
@import "./assets/scss/style.scss";
</style>
<script setup>
import TopBar from "@/components/TopBar.vue";
import SiteFooter from "@/components/SiteFooter.vue";

import { useUserStore } from "@/stores/user";
import { onMounted } from "vue";

const UserStore = useUserStore();

onMounted(() => {
  UserStore.fetch();
});
</script>