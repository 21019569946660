<template>
  <div id="nav" class="d-flex">
    <router-link to="/" class="tdn uc link">
      <span class="material-icons pe-2 py-2">home</span>
      <span class="small d-none d-md-block">Home</span>
    </router-link>

    <router-link
      v-if="!UserStore.isStaff && UserStore.isLoggedIn"
      to="/applications"
      class="tdn uc link"
    >
      <span class="material-icons pe-2 py-2">dashboard</span>
      <span class="small d-none d-md-block">Applications</span>
    </router-link>

    <router-link v-else-if="UserStore.isStaff" to="/staff" class="tdn uc link">
      <span class="material-icons pe-2 py-2">dashboard</span>
      <span class="small d-none d-md-block">Staff</span>
    </router-link>

    <a
      v-if="UserStore.isLoggedIn"
      href="/accounts/logout"
      class="tdn uc link link-end"
    >
      <span class="material-icons pe-2 py-2">logout</span>
      <span class="small d-none d-md-block">Log Out</span>
    </a>
    <a v-else href="/accounts/login" class="tdn uc link link-end">
      <span class="material-icons pe-2 py-2">login</span>
      <span class="small d-none d-md-block">Log In</span>
    </a>
  </div>
</template>

<style lang="scss" scoped>
#nav {
  .link {
    display: flex;
    flex-direction: row;
    padding: 0.5em 1em;
    justify-content: center;
    align-items: center;

    transition: all 200ms ease-in-out;

    &:hover {
      background-color: #4278d2;
      color: white;
    }
  }

  .link-end {
    margin-left: auto;
  }

  .router-link-active {
    background-color: #4278d2;
    color: white;
  }
}
</style>


<script setup>
import { useUserStore } from "@/stores/user";
const UserStore = useUserStore();
</script>