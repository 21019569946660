<template>
  <div class="bg-dark py-3 pb-1">
    <div class="container">
      <footer
        class="d-flex flex-wrap justify-content-center align-items-center my-2"
      >
        <div class="col-md-6 d-flex align-items-center text-light">
          <img src="/logo.png" class="logo" />
          <div>
            <span class="h3">Gandhara University</span>
            <ul class="list-unstyled smaller mt-2">
              <li>
                <span class="material-icons">phone</span>
                <span class="text"> 091-5711151-3 </span>
              </li>
              <li>
                <span class="material-icons">email</span>
                <span class="text"> admission@gandhara.edu.pk </span>
              </li>
              <li>
                <span class="material-icons">smartphone</span>
                <span class="text"> +92-302-8603777 </span>
              </li>
            </ul>
          </div>
        </div>

        <ul class="nav col-md-6 justify-content-end list-unstyled d-flex pt-4 pt-md-0">
          <li>
            <a
              class="social-icon"
              href="https://facebook.com/Gandhara.University.Peshawar"
            >
              <img :src="'/facebook.svg'" />
            </a>
          </li>
          <li>
            <a
              class="social-icon"
              href="https://www.youtube.com/channel/UC6oG2wGqd5llJFK_RkdQ6Kw"
            >
              <img :src="'/youtube.svg'" />
            </a>
          </li>
          <li>
            <a class="pt-1 tdn text-light" href="https://voidsolutions.net/">
              <span class="smaller fw-light">Powered by Void Solutions</span>
            </a>
          </li>
        </ul>
      </footer>
    </div>
  </div>
</template>


<style lang="scss" scoped>
.contact {
  justify-content: center;
  align-items: center;
  .material-icons {
    font-size: 1.2rem;
    vertical-align: middle;
  }
  .text {
    font-size: 0.8rem;
    vertical-align: middle;
  }
}

.social-icon img {
  width: 25px;
  margin: 0 0.5em;
}

.logo {
  width: 150px;
  height: 150px;
  margin-right: 10px;
}
</style>

<script>
export default {
  data() {
    return {
      year: 2021,
    };
  },
  created() {
    this.year = new Date().getFullYear();
  },
};
</script>