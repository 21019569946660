import { defineStore } from "pinia";
import { ref } from 'vue'
import axios from "axios";

import { useToast } from "vue-toastification";
const toast = useToast();

export const useUserStore = defineStore('user', () => {
    const isLoggedIn = ref(false)
    const isStaff = ref(false)
    const staffPerms = ref({
        can_send_emails: false,
        can_manage_merit_lists: false,
        is_admin: false
    })

    function fetch() {
        if (process.env.VUE_APP_DEBUG === '1') {
            let staffStatus = true
            let loggedIn = true

            staffPerms.value = {
                can_send_emails: true,
                can_manage_merit_lists: true,
                is_admin: true
            }

            isLoggedIn.value = loggedIn;
            isStaff.value = staffStatus;
            localStorage.setItem('isLoggedIn', loggedIn)
            localStorage.setItem('isStaff', staffStatus)
        }

        isStaff.value = localStorage.getItem('isStaff') === 'true'
        isLoggedIn.value = localStorage.getItem('isLoggedIn') === 'true'

        axios.post('/authentication/').then(() => {
            isLoggedIn.value = true
            localStorage.setItem('isLoggedIn', true)
        }).catch((e) => {
            if (e.response && e.response.status == 403) {
                isLoggedIn.value = false
                localStorage.setItem('isLoggedIn', false)
                toast.error("You are not loggged in",)
            }
        })


        axios.post("/authentication/staff/").then((res) => {
            staffPerms.value = res.data
            isStaff.value = true
            localStorage.setItem('isStaff', true)
        }).catch((e) => {
            if (e.response && e.response.status == 403) {
                isStaff.value = false
                localStorage.setItem('isStaff', false)
            }
        })
    }

    return {
        isLoggedIn,
        isStaff,
        staffPerms,
        fetch
    }
})
