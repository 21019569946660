import { createStore } from "vuex";

export default createStore({
  state() {
      return {
          institutes: [],
          isStaff: true,
          isLoggedIn: true,
          staffPerms: {
            can_send_emails: true,
            can_manage_merit_lists: true,
          },
          sessions: [],
          hj_sessions: [],
          notifications: [],
          downloads: [],
      }
  },
  mutations: {
      initialiseStore(state) {
          if (localStorage.getItem('institutes')) {
              state.institutes = JSON.parse(localStorage.getItem('institutes'));
          }
      },
      updateInstitutes(state, data) {
          state.institutes = data;
          localStorage.setItem('institutes', JSON.stringify(data))
      },
      isStaff(state) {
          state.isStaff = true;
      },
      isNotStaff(state) {
          state.isStaff = false;
      },
      isLoggedIn(state) {
          state.isLoggedIn = true;
      },
      isNotLoggedIn(state) {
          state.isLoggedIn = false;
      },
      updateStaffPerms(state, data) {
          state.staffPerms = data;
      },
      updateSessions(state, data) {
          if (state.isStaff)
              state.sessions = data;
      },
      updateHJSessions(state, data) {
          if (state.isStaff)
              state.hj_sessions = data;
      },
      updateNotifications(state, data) {
          state.notifications = data;
      },
      updateDownloads(state, data) {
          state.downloads = data;
      },
  },
})