import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/HomePage.vue'
import CollegePage from '../views/CollegePage.vue'
import CollegeHouseJobs from '../views/CollegeHouseJobs.vue'

import applicationRoutes from './applicationRoutes.js'
import staffRoutes from './staffRoutes.js'

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/applications',
    name: 'Applications',
    component: () => import('../views/ApplicationsPage.vue')
  },
  {
    path: '/college/:collegeId',
    name: 'CollegePage',
    component: CollegePage,
    props: true
  },
  {
    path: '/college/:collegeId/housejob',
    name: 'CollegeHouseJobs',
    component: CollegeHouseJobs,
    props: true
  },
  ...applicationRoutes,
  ...staffRoutes,
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
