export const functions = {
    SMSCalculator: {
        // Encoding
        encoding: {
            UTF16: [70, 64, 67],
            GSM_7BIT: [160, 146, 153],
            GSM_7BIT_EX: [160, 146, 153],
        },

        // Charset
        charset: {
            gsmEscaped: "\\^{}\\\\\\[~\\]|€",
            gsm: "@£$¥èéùìòÇ\\nØø\\rÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ !\"#¤%&'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà",
        },

        // Regular Expression
        regex: function () {
            return {
                gsm: RegExp(`^[${this.charset.gsm}]*$`),
                gsmEscaped: RegExp(`^[\\${this.charset.gsmEscaped}]*$`),
                gsmFull: RegExp(`^[${this.charset.gsm}${this.charset.gsmEscaped}]*$`),
            };
        },

        // Method
        detectEncoding: function (text) {
            if (text.match(this.regex().gsm)) {
                return this.encoding.GSM_7BIT;
            } else if (text.match(this.regex().gsmFull)) {
                return this.encoding.GSM_7BIT_EX;
            } else {
                return this.encoding.UTF16;
            }
        },
        getEscapedCharCount: function (text) {
            return [...text].reduce(
                (acc, char) => acc + (char.match(this.regex().gsmEscaped) ? 1 : 0),
                0
            );
        },
        getPartData: function (totalLength, encoding) {
            let maxCharCount = encoding[2];
            let numberOfSMS = Math.ceil(totalLength / maxCharCount);
            let remaining =
                maxCharCount -
                (totalLength -
                    (encoding[0] + encoding[1] + encoding[2] * (numberOfSMS - 3)));

            if (totalLength <= encoding[0]) {
                maxCharCount = encoding[0];
                numberOfSMS = 1;
                remaining = maxCharCount - totalLength;
            } else if (
                totalLength > encoding[0] &&
                totalLength <= encoding[0] + encoding[1]
            ) {
                maxCharCount = encoding[1];
                numberOfSMS = 2;
                remaining = maxCharCount - (totalLength - encoding[0]);
            }

            return {
                maxCharCount,
                numberOfSMS,
                remaining,
                totalLength,
            };
        },
        getCount: function (text) {
            let length = text.length;
            const encoding = this.detectEncoding(text);

            if (encoding === this.encoding.GSM_7BIT_EX) {
                length += this.getEscapedCharCount(text);
            }

            return this.getPartData(length, encoding);
        },
    },

    convertNumberToWords: function (amount) {
        var words = new Array();
        words[0] = '';
        words[1] = 'One';
        words[2] = 'Two';
        words[3] = 'Three';
        words[4] = 'Four';
        words[5] = 'Five';
        words[6] = 'Six';
        words[7] = 'Seven';
        words[8] = 'Eight';
        words[9] = 'Nine';
        words[10] = 'Ten';
        words[11] = 'Eleven';
        words[12] = 'Twelve';
        words[13] = 'Thirteen';
        words[14] = 'Fourteen';
        words[15] = 'Fifteen';
        words[16] = 'Sixteen';
        words[17] = 'Seventeen';
        words[18] = 'Eighteen';
        words[19] = 'Nineteen';
        words[20] = 'Twenty';
        words[30] = 'Thirty';
        words[40] = 'Forty';
        words[50] = 'Fifty';
        words[60] = 'Sixty';
        words[70] = 'Seventy';
        words[80] = 'Eighty';
        words[90] = 'Ninety';
        amount = amount.toString();
        var value = ""
        var i, j = 0
        var atemp = amount.split(".");
        var number = atemp[0].split(",").join("");
        var n_length = number.length;
        var words_string = "";
        if (n_length <= 9) {
            var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
            var received_n_array = new Array();
            for (i = 0; i < n_length; i++) {
                received_n_array[i] = number.substr(i, 1);
            }
            for (i = 9 - n_length, j = 0; i < 9; i++, j++) {
                n_array[i] = received_n_array[j];
            }
            for (i = 0, j = 1; i < 9; i++, j++) {
                if (i == 0 || i == 2 || i == 4 || i == 7) {
                    if (n_array[i] == 1) {
                        n_array[j] = 10 + parseInt(n_array[j]);
                        n_array[i] = 0;
                    }
                }
            }
            value = "";
            for (i = 0; i < 9; i++) {
                if (i == 0 || i == 2 || i == 4 || i == 7) {
                    value = n_array[i] * 10;
                } else {
                    value = n_array[i];
                }
                if (value != 0) {
                    words_string += words[value] + " ";
                }
                if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
                    words_string += "Crores ";
                }
                if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
                    words_string += "Lakhs ";
                }
                if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
                    words_string += "Thousand ";
                }
                if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
                    words_string += "Hundred and ";
                } else if (i == 6 && value != 0) {
                    words_string += "Hundred ";
                }
            }
            words_string = words_string.split("  ").join(" ");
        }
        console.log(words_string)
        return words_string;
    },

    printFromHTML: function (elementIDToPrint) {
        // Get HTML to print from element
        const prtHtml = document.getElementById(elementIDToPrint).outerHTML;

        // Get all stylesheets HTML
        let stylesHtml = '';

        for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
            stylesHtml += node.outerHTML;
        }

        // Open the print window
        const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

        WinPrint.document.write(`<!DOCTYPE html>
                                <html>
                                <head>
                                    ${stylesHtml}
                                </head>
                                <body>
                                    ${prtHtml}
                                </body>
                                </html>`);

        WinPrint.document.close();
        WinPrint.focus();
        window.setTimeout(() => { WinPrint.focus(); WinPrint.print() }, 500)
    },

    validateCNIC: function (string) {
        string = string.toString();
        if (string.length == 13) return true
        else return false
    },
    validateMobileNumber: function (string) {
        string = string.toString();
        if (string.length == 11) return true
        else return false
    },
    validateLandlineNumber: function (string) {
        string = string.toString();
        if (string.length < 20) return true
        else return false
    },
    validateDateInPastOrToday: function (string) {
        if (Date.parse(string) - Date.parse(new Date()) <= 0) return true
        else return false
    },
    validateDateInPast: function (string) {
        if (Date.parse(string) - Date.parse(new Date()) < 0) return true
        else return false
    },
    formatDateToLongFormat: function (string) {
        var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        var today = new Date(string);

        return today.toLocaleDateString("en-UK", options)
    },
    sort: function (event) {
        const getCellValue = (tr, idx) =>
            tr.children[idx].innerText || tr.children[idx].textContent;

        const comparer = (idx, asc) => (a, b) =>
            ((v1, v2) =>
                v1 !== "" && v2 !== "" && !isNaN(v1) && !isNaN(v2)
                    ? v1 - v2
                    : v1.toString().localeCompare(v2))(
                        getCellValue(asc ? a : b, idx),
                        getCellValue(asc ? b : a, idx)
                    );

        const table = event.target.closest("table");
        const tbody = table.querySelector("tbody");
        Array.from(tbody.querySelectorAll("tr"))
            .sort(
                comparer(
                    Array.from(event.target.parentNode.children).indexOf(event.target),
                    (this.asc = !this.asc)
                )
            )
            .forEach((tr) => tbody.appendChild(tr));
    },
    download_table_as_csv: function (table_id, separator = ',') {
        // Select rows from table_id
        var rows = document.querySelectorAll('table#' + table_id + ' tr');
        // Construct csv
        var csv = [];
        for (var i = 0; i < rows.length; i++) {
            var row = [], cols = rows[i].querySelectorAll('td:not(.no-csv), th:not(.no-csv)');
            for (var j = 0; j < cols.length; j++) {
                // Clean innertext to remove multiple spaces and jumpline (break csv)
                var data = cols[j].innerText.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
                // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
                data = data.replace(/"/g, '""');
                // Push escaped string
                row.push('"' + data + '"');
            }
            csv.push(row.join(separator));
        }
        var csv_string = csv.join('\n');
        // Download it
        var filename = 'export_' + table_id + '_' + new Date().toLocaleDateString() + '.csv';
        var link = document.createElement('a');
        link.style.display = 'none';
        link.setAttribute('target', '_blank');
        link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_string));
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

};