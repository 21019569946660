import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import axios from 'axios'

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import { createPinia } from 'pinia'

const app = createApp(App)

const options = {
    draggable: false
};

app.use(Toast, options)
app.use(router)
app.use(createPinia())

import store from "./store";
app.use(store)



var axiosDefaults = require("axios/lib/defaults");
axiosDefaults.xsrfCookieName = "csrftoken";
axiosDefaults.xsrfHeaderName = "X-CSRFToken";

if (process.env.VUE_APP_API_ENDPOINT)
    axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT

if (process.env.VUE_APP_DEBUG === '1') {
    axios.defaults.headers.common = { 'Authorization': `Token ${process.env.VUE_APP_AUTH_TOKEN}` }
    app.config.globalProperties.$debug = true
}

app.config.globalProperties.axios = axios
app.mount('#app')
