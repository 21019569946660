<template>
  <div class="d-flex justify-content-center bg-primary mb-3">
    <div class="logo">
      <img
        style="max-width: 176px; max-height: 176px; padding: 2em"
        :alt="title + ' Logo'"
        :src="img"
      />
    </div>
    <div class="text-start flex-column justify-content-center d-md-flex d-none text-white">
      <h1 v-if="title == 'PG'" class="mb-0 uc">
        Postgraduate Admissions
      </h1>
      <h1 v-else class="mb-0 uc">
        {{ title }}
      </h1>
      <p class="fw-light mt-1">
        {{ subtitle }}
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  props: {
    title: String,
    subtitle: String,
    img: String,
  },
};
</script>