<template>
  <div class="fs-overlay">
    <div class="content">
      <div class="body text-white">
        <img class="logo" src="/logo.png" alt="Gandhara University" />
        <div class="lds-fs">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <p class="lead">{{ text }}</p>
        <p class="lead" v-if="!text && loadingTime > 5">
          Contact us if loading takes too long <br />
          Phone: 091-5711151-3 <br />
          Email: admission@gandhara.edu.pk
        </p>
      </div>
      <div class="footer-faded">
        <div class="pb-2">
          <a href="https://voidsolutions.net/" class="text-center text-blue"
            >Powered by Void Solutions</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";

const loadingTime = ref(0);

onMounted(() => {
  const interval = setInterval(() => {
    loadingTime.value++;
  }, 1000);
  setTimeout(() => {
    clearInterval(interval);
  }, 10000);
});

defineProps({
  text: {
    type: String,
    default: "Loading, please wait",
  },
});
</script>

<style lang="scss" scoped>
.fs-overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bs-primary);
  z-index: 999;

  .logo {
    width: 250px;
    text-align: center;
    max-width: 50%;
  }

  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .footer-faded {
    margin-top: auto;

    a {
      text-decoration: none;
      padding-bottom: 1em;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
  }
}

.loader {
  color: black;
  font-size: 32px;
  overflow: hidden;
  text-align: center;
}

.lds-fs {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-fs div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: rgb(0, 0, 0);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-fs div:nth-child(1) {
  left: 8px;
  animation: lds-fs1 0.6s infinite;
}
.lds-fs div:nth-child(2) {
  left: 8px;
  animation: lds-fs2 0.6s infinite;
}
.lds-fs div:nth-child(3) {
  left: 32px;
  animation: lds-fs2 0.6s infinite;
}
.lds-fs div:nth-child(4) {
  left: 56px;
  animation: lds-fs3 0.6s infinite;
}
@keyframes lds-fs1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-fs3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-fs2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
</style>